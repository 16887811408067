<template>
    <div>
        <Header />
        <broadcamb />
        <GalleryComponent />
    </div>

</template>

<script>


import Header from '../components/layouts/Header.vue'
import broadcamb from '../components/layouts/broadCambPart.vue'
import GalleryComponent from '../components/gallery/galleryComponent.vue'

export default {
    name: 'GalleryPage',
    components: {

        Header,
        broadcamb,  
        GalleryComponent

    },
}
</script>

<style scoped></style>