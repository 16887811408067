<template>
  <div class="slider-container">
    <div class="slider-blur-overlay"></div>
    <div class="row justify-content-center mb-5 sm-mb-30px">
      <div class="col-xl-6 col-lg-8 col-md-10 text-center mt-4">
        <h3 class="text-dark mb-0 fw-600">Our Clients</h3>
      </div>
    </div>
    <swiper
      :loop="true"
      :autoplay="{
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }"
      :breakpoints="breakpoints"
      :space-between="20"
      :speed="800"
      :grabCursor="true"
      :effect="'slide'"
      :slidesPerGroup="1"
      :centeredSlides="true"
      :cssMode="false"
      :freeMode="{
        enabled: true,
        momentum: true,
        momentumRatio: 0.8,
        momentumVelocityRatio: 0.8
      }"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide 
        v-for="(logo, index) in serviceImages" 
        :key="index" 
        class="slide"
        v-slot="{ isActive }"
      >
        <img 
          :src="logo" 
          class="logo" 
          :class="{ 'active': isActive }"
        />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectCreative, FreeMode } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/free-mode';

const modules = [Autoplay, EffectCreative, FreeMode];
const serviceImages = ref([]);

const fetchData = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/ClientsPage`);
    const pageData = response.data;
    if (!pageData || !pageData.sections) {
      throw new Error('Invalid response data');
    }
    serviceImages.value = pageData.sections.flatMap(section =>
      section.images.map(image => image.url || 'content/surveyor/images/default_image.jpg')
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    serviceImages.value = ['content/surveyor/images/default_image.jpg'];
  }
};

onMounted(fetchData);

const breakpoints = {
  768: {
    slidesPerView: 6,
    spaceBetween: 30,
  },
  520: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  0: {
    slidesPerView: 3,
    spaceBetween: 15,
  },
};
</script>

<style scoped>
.slider-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  overflow: hidden;
}

.slider-blur-overlay::before,
.slider-blur-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  z-index: 2;
  pointer-events: none;
}

.slider-blur-overlay::before {
  left: 0;
  background: linear-gradient(to right, 
    rgba(255, 255, 255, 1) 0%, 
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0) 100%
  );
}

.slider-blur-overlay::after {
  right: 0;
  background: linear-gradient(to left, 
    rgba(255, 255, 255, 1) 0%, 
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0) 100%
  );
}

h2 {
  text-align: center;
  padding: 20px;
}

.slide img {
  width: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
              opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity;
}

.swiper-slide:hover {
  transform: scale(1.05);
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  opacity: 0.75;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  filter: grayscale(30%);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translateZ(0);
}

.logo:hover {
  opacity: 1;
  filter: grayscale(0%);
  transform: scale(1.05);
}

.logo.active {
  opacity: 1;
  filter: grayscale(0%);
  transform: scale(1.1);
}

.swiper {
  padding: 20px 0;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: visible;
}

.swiper:hover .swiper-slide {
  opacity: 0.7;
}

.swiper:hover .swiper-slide:hover {
  opacity: 1;
}

/* Add smooth transition for surrounding slides */
.swiper-slide-prev,
.swiper-slide-next {
  transform: scale(0.9);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Enhanced grab cursor interaction */
.swiper-grab {
  cursor: grab;
}

.swiper-grabbing {
  cursor: grabbing;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .slider-container {
    padding: 0 20px;
  }
  
  .slider-blur-overlay::before,
  .slider-blur-overlay::after {
    width: 60px;
  }
}

@media (max-width: 520px) {
  .slider-container {
    padding: 0 15px;
  }
  
  .slider-blur-overlay::before,
  .slider-blur-overlay::after {
    width: 40px;
  }
}
</style>