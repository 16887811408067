<template>
    <div class="contact-buttons">
        <a :href="'tel:' + mainPhoneNumber" class="contact-btn phone-btn" title="Call Us">
            <div class="btn-content">
                <i class="bi bi-telephone-fill"></i>
            </div>
        </a>
        <div class="divider"></div>
        <a :href="whatsappLink" target="_blank" class="contact-btn whatsapp-btn" title="WhatsApp Us">
            <div class="btn-content">
                <i class="bi bi-whatsapp"></i>
            </div>
        </a>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

const sections = ref([]);
const mainPhoneNumber = computed(() => {
    if (sections.value[3]?.content) {
        return sections.value[3].content.split(',')[0].trim();
    }
    return '';
});

const whatsappLink = computed(() => {
    const number = mainPhoneNumber.value.replace(/[^0-9]/g, '');
    return `https://wa.me/${number}`;
});

const fetchData = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/ContactUsPage`);
        sections.value = response.data.sections;
    } catch (error) {
        console.error('Error fetching contact information:', error);
    }
};

onMounted(fetchData);
</script>

<style scoped>
.contact-buttons {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    background: white;
    border-radius: 0 12px 12px 0;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}

.contact-btn {
    display: block;
    color: #dc3545;
    text-decoration: none;
    padding: 12px;
    width: 50px;
}

.btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-btn i {
    font-size: 22px;
    min-width: 22px;
    animation: subtle-pulse 2s infinite;
}

.divider {
    height: 1px;
    background: #e0e0e0;
    margin: 0 8px;
}

@keyframes subtle-pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

/* Mobile Styles - keeping just for consistency in layout */
@media (max-width: 768px) {
    .contact-buttons {
        display: flex;
        flex-direction: column;
    }
}
</style>