import { createRouter, createWebHistory } from 'vue-router'
import Home from './Pages/Home.vue'
import Services from './Pages/Services.vue'
import WhyUs from './Pages/WhyUs.vue'
import Contact from './Pages/Contact.vue'
import TestoMonials from './Pages/TestoMonials.vue'
import Apps from './Pages/Apps.vue'
import JoinUs from './Pages/JoinUs.vue'
import Gallery from './Pages/Gallery.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/services',
    name: 'Services',
    component: Services
},
{
    path: '/why-us',
    name: 'WhyUs',
    component: WhyUs
},
{
    path: '/contact',
    name: 'Contact',
    component: Contact
},
{
    path: '/testimonials',
    name: 'TestoMonials',
    component: TestoMonials
},
{
    path: '/apps',
    name: 'Apps',
    component: Apps
},
{
    path: '/join-us',
    name: 'JoinUs',
    component: JoinUs
},
{
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
}

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router