<template>
    <div>
        <!-- Key Features -->
        <section class="features-section">
            <div class="container">
                <!-- Section Header -->
                <div class="row justify-content-center mb-5">
                    <div class="col-lg-6 text-center">
                        <h1 class="section-title">Key Features</h1>
                        <div class="title-separator"></div>
                    </div>
                </div>

                <!-- Features Grid -->
                <div class="row g-4">
                    <div class="col-xl-3 col-lg-6 col-md-6" v-for="(section, index) in sections" :key="index">
                        <div class="flip-card">
                            <div class="flip-card-inner">
                                <!-- Card Front -->
                                <div class="flip-card-front">
                                    <div class="icon-box">
                                        <img :src="section.image" :alt="section.title">
                                    </div>
                                    <h3>{{ section.title }}</h3>
                                </div>

                                <!-- Card Back -->
                                <div class="flip-card-back">
                                    <div class="content">
                                        <p v-html="formatContent(section.content)"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="benefits-section">
            <div class="container">
                <!-- Section Header -->
                <div class="row justify-content-center mb-5">
                    <div class="col-lg-6 text-center">
                        <h1 class="section-title">Benefits</h1>
                        <div class="title-separator"></div>
                    </div>
                </div>

                <!-- Benefits Grid -->
                <div class="row g-4">
                    <div class="col-xl-3 col-lg-6 col-md-6" v-for="(section, index) in sectionsBenfits" :key="index">
                        <div class="benefit-card">
                            <div class="benefit-card-inner">
                                <!-- Card Front -->
                                <div class="benefit-card-front">
                                    <div class="icon-box">
                                        <img :src="section.image" :alt="section.title">
                                    </div>
                                    <h3>{{ section.title }}</h3>
                                </div>

                                <!-- Card Back -->
                                <div class="benefit-card-back">
                                    <div class="content">
                                        <p v-html="formatBenefitContent(section.content)"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="overflow-hidden">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-11 offset-lg-0 offset-md-1 position-relative md-mb-20px">
                        <figure class="position-relative m-0 text-center">
                            <img :src="secondSectionImageExp" alt="">
                            <figcaption class="position-absolute bottom-80px left-0px"
                                data-anime='{ "translateY": [-50, 0], "opacity": [0,1], "duration": 800, "delay": 1000, "staggervalue": 300, "easing": "easeOutQuad" }'>
                                <img :src="secondSectionImageExp" class="animation-float" alt="">
                            </figcaption>
                        </figure>
                    </div>
                    <div class="col-xl-5 offset-xl-1 col-lg-6">
                        <h3 class="fw-700 text-dark-gray ls-minus-1px">{{ titleSectionExp }}</h3>
                        <div class="row row-cols-1 row-cols-lg-1 row-cols-md-2 justify-content-center mb-40px md-mb-0">
                            <!-- start features box item -->
                            <div class="col icon-with-text-style-08 mb-30px">
                                <div class="feature-box feature-box-left-icon overflow-hidden">
                                    <div class="feature-box-content last-paragraph-no-margin">
                                        <span class="d-inline-block fs-18  text-dark-gray mb-5px ls-minus-05px">{{
                                            secondSectionContentExp }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="get-started-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-10">
                        <div class="download-section">
                            <div class="decorative-circle circle-1"></div>
                            <div class="decorative-circle circle-2"></div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <h3 class="download-title">Download Now</h3>
                                    <p class="download-description">Get started with our app today!</p>

                                    <div class="store-buttons">
                                        <a :href="playStoreLink" target="_blank" class="store-button google-play">
                                            <img src="https://i.pinimg.com/736x/21/03/98/2103982ca2dcefc3d4f4dda616eeee53.jpg"
                                                alt="Get it on Google Play">
                                        </a>
                                        <a :href="appStoreLink" target="_blank" class="store-button app-store">
                                            <img src="https://w7.pngwing.com/pngs/506/939/png-transparent-app-store-logo-iphone-app-store-get-started-now-button-electronics-text-telephone-call.png"
                                                alt="Download on the App Store">
                                        </a>
                                    </div>

                                    <div class="features-list">
                                        <div class="feature-item">
                                            <i class="fas fa-check-circle"></i>
                                            <span>Easy to use interface</span>
                                        </div>
                                        <div class="feature-item">
                                            <i class="fas fa-check-circle"></i>
                                            <span>24/7 customer support</span>
                                        </div>
                                        <div class="feature-item">
                                            <i class="fas fa-check-circle"></i>
                                            <span>Regular updates</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 d-none d-lg-block">
                                    <div class="device-mockup">
                                        <img src="https://pub-a99445c9dcce4e8f89bb4c3007d44c73.r2.dev/app-image.jpg    "
                                            alt="App Preview" class="app-preview">
                                        <div class="device-frame"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const firstSectionImages = ref([]);
const sections = ref([]);
const titles = ref([]);
const serviceSections = ref([]);
const serviceImages = ref([]);

const sectionsBenfits = ref([]);
const titlesBenfits = ref([]);
const serviceSectionsBenfits = ref([]);
const serviceImagesBenfits = ref([]);

const titleSectionExp = ref('Loading...');
const secondSectionContentExp = ref('Loading...');
const secondSectionImageExp = ref('');

const titleSectionGet = ref('Loading...');
const secondSectionContentGet = ref('Loading...');
const secondSectionImageGet = ref('');

const playStoreLink = ref('');
const appStoreLink = ref('');

const formatContent = (content) => {
    if (!content) return '';
    return content.split('•')
        .filter(item => item.trim())
        .map(item => `<div class="content-item">• ${item.trim()}</div>`)
        .join('');
};

const formatBenefitContent = (content) => {
    if (!content) return '';
    return content.split('•')
        .filter(item => item.trim())
        .map(item => `<div class="benefit-item">• ${item.trim()}</div>`)
        .join('');
};

const getSliderData = async () => {
    const page = 'AppsPage';
    const sectionID = '665eff8832b3fc659b911f9c';
    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        if (section && section.images && section.images.length > 0) {
            firstSectionImages.value = section.images.map(image => image.url);
        }
    } catch (error) {
        console.error('Failed to fetch images:', error);
        firstSectionImages.value = ['default_image_url'];
    }
};

const fetchData = async () => {
    const page = 'AppsPage';
    const sectionIDs = [
        "665f1226ac1f1712687098e9",
        "665f123aac1f1712687098f1",
        "665f124bac1f1712687098fb",
        "665f127aac1f171268709910",
        "665f128cac1f17126870991e",

    ];

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sections.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : 'content/surveyor/images/default_image.jpg'
        }));

        titles.value = filteredSections.map(section => section.title);
        serviceSections.value = filteredSections.map(section => section.content);
        serviceImages.value = filteredSections.map(section => section.images[0].url);

    } catch (error) {
        console.error('Error fetching data:', error);
        sections.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: 'content/surveyor/images/default_image.jpg'
        }];
    }
};

const fetchDataofBenefits = async () => {
    const page = 'AppsPage';
    const sectionIDs = [

        "665f12adac1f17126870992e",
        "665f12c6ac1f171268709940",
        "665f12d4ac1f171268709954",
        "665f12e4ac1f17126870996a"
    ];

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}`);
        const pageData = response.data;
        const filteredSections = pageData.sections.filter(section => sectionIDs.includes(section._id));

        sectionsBenfits.value = filteredSections.map(section => ({
            title: section.title || 'Default Title',
            content: section.content || 'Default content.',
            image: (section.images && section.images.length > 0) ? section.images[0].url : ''
        }));

        titlesBenfits.value = filteredSections.map(section => section.title);
        serviceSectionsBenfits.value = filteredSections.map(section => section.content);
        serviceImagesBenfits.value = filteredSections.map(section => section.images[0].url);

    } catch (error) {
        console.error('Error fetching data:', error);
        sectionsBenfits.value = [{
            title: 'Error',
            content: 'Error loading content.',
            image: ''
        }];
    }
};

const fetchDataExp = async () => {
    const page = 'AppsPage';
    const sectionID = '665f39e5de93dd002a38d895';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSectionExp.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImageExp.value = section.images[0].url;
        }
        secondSectionContentExp.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImageExp.value = 'default_image_url'; // replace with your default image url
        secondSectionContentExp.value = 'Error loading content';
    }
};


const fetchDataGetStarted = async () => {
    const page = 'AppsPage';
    const sectionID = '665f39f1de93dd002a38d8b3';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSectionGet.value = section.title;

        // Assuming these are stored in the section's metadata or custom fields
        playStoreLink.value = section.playStoreUrl || '#';
        appStoreLink.value = section.appStoreUrl || '#';

        if (section && section.images && section.images.length > 0) {
            secondSectionImageGet.value = section.images[0].url;
        }
        secondSectionContentGet.value = section.content;
    } catch (error) {
        console.error(error);
        secondSectionImageGet.value = 'default_image_url';
        secondSectionContentGet.value = 'Error loading content';
    }
};


onMounted(() => {
    getSliderData();
    fetchData();
    fetchDataofBenefits();
    fetchDataExp();
    fetchDataGetStarted();
});
</script>



<style scoped>
.app-store-btn {
    transition: opacity 0.3s ease;
}

.app-store-btn:hover {
    opacity: 0.8;
}

.gap-3 {
    gap: 1rem;
}

.get-started-section {
    padding: 100px 0;
    background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
    overflow: hidden;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2d3436;
    margin-bottom: 1rem;
}

.section-description {
    font-size: 1.1rem;
    color: #636e72;
    max-width: 800px;
    margin: 0 auto;
}

.app-preview-wrapper {
    position: relative;
    padding: 20px;
}

.app-preview-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    position: relative;
    z-index: 2;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.app-preview-image:hover {
    transform: translateY(-10px);
}

.floating-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.float-element {
    position: absolute;
    border-radius: 50%;
    opacity: 0.1;
}

.element-1 {
    width: 200px;
    height: 200px;
    background: linear-gradient(45deg, #6c5ce7, #a8e6cf);
    top: -50px;
    left: -50px;
    animation: float 6s ease-in-out infinite;
}

.element-2 {
    width: 150px;
    height: 150px;
    background: linear-gradient(45deg, #00cec9, #81ecec);
    bottom: -30px;
    right: -30px;
    animation: float 8s ease-in-out infinite;
}

.download-section {
    position: relative;
    padding: 60px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.download-title {
    font-size: 2rem;
    font-weight: 700;
    color: #2d3436;
    margin-bottom: 1rem;
}

.download-description {
    color: #636e72;
    margin-bottom: 2rem;
}

.store-buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 2rem;
}

.store-button {
    transition: transform 0.3s ease;
}

.store-button:hover {
    transform: translateY(-5px);
}

.store-button img {
    height: 50px;
    border-radius: 8px;
}

.features-list {
    margin-top: 2rem;
}

.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #2d3436;
}

.feature-item i {
    color: #00b894;
    margin-right: 10px;
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

@media (max-width: 991px) {
    .get-started-section {
        padding: 60px 0;
    }

    .download-section {
        margin-top: 40px;
    }

    .store-buttons {
        justify-content: center;
    }
}

/* Title Animation */
.title-animate {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.title-animate.slide-in {
    opacity: 1;
    transform: translateY(0);
}

/* Content Fade Up Animation */
.fade-up {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0.2s;
}

.fade-up.show {
    opacity: 1;
    transform: translateY(0);
}

/* Store Buttons Animation */
.store-buttons {
    transition-delay: 0.4s;
}

.app-store-btn {
    position: relative;
    transition: transform 0.3s ease;
    will-change: transform;
}

.app-store-btn:hover {
    transform: translateY(-5px);
}

.app-store-btn img {
    transition: all 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.app-store-btn img.hovered {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

/* Image Animations */
.image-container {
    opacity: 0;
    transform: translateX(50px);
    transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.image-container.slide-in {
    opacity: 1;
    transform: translateX(0);
}

.main-image {
    transition: all 0.5s ease;
}

.main-image.floating {
    animation: float 6s ease-in-out infinite;
}

.floating-image {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0.6s;
}

.floating-image.show {
    opacity: 1;
    transform: translateY(0);
}

.animation-float {
    animation: floatSecondary 5s ease-in-out infinite;
    animation-delay: 1s;
}

@keyframes float {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes floatSecondary {

    0%,
    100% {
        transform: translateY(0) rotate(2deg);
    }

    50% {
        transform: translateY(-15px) rotate(-2deg);
    }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .get-started-section {
        padding: 60px 0;
    }

    .image-container {
        margin-top: 40px;
    }
}

/* Hover Effects */
.feature-box {
    transition: all 0.3s ease;
}

.feature-box:hover {
    transform: translateX(5px);
}

/* Optional Parallax Effect */
.image-column {
    perspective: 1000px;
}

.image-container:hover .main-image {
    transform: translateZ(20px);
    transition: transform 0.3s ease;
}

.features-section {
    padding: 80px 0;
    background-color: #fff;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
}

.title-separator {
    width: 80px;
    height: 4px;
    background: linear-gradient(45deg, #ff3366, #ff6b6b);
    margin: 0 auto;
    border-radius: 2px;
}

/* Flip Card Styles */
.flip-card {
    perspective: 1500px;
    height: auto;
    min-height: 320px;
    cursor: pointer;
    margin-bottom: 20px;
}

.flip-card-inner {
    min-height: 320px;
    height: 100%;
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 15px;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
}

.flip-card-front {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.flip-card-back {
    background: linear-gradient(135deg, #ff3366, #ff6b6b);
    color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.icon-box {
    width: 85px;
    height: 85px;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 50%;
    background: rgba(255, 51, 102, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.icon-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.flip-card:hover .icon-box {
    transform: scale(1.1);
}

.flip-card-front h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.content {
    font-size: 0.95rem;
    line-height: 1.6;
}

.content p {
    margin: 0;
}

/* Content formatting */
.content-item {
    text-align: left;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: flex-start;
}

.content-item:last-child {
    margin-bottom: 0;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .flip-card {
        min-height: 350px;
    }

    .flip-card-inner {
        min-height: 350px;
    }
}

@media (max-width: 991px) {
    .flip-card {
        min-height: 300px;
    }

    .flip-card-inner {
        min-height: 300px;
    }
}

@media (max-width: 767px) {
    .flip-card {
        min-height: 280px;
    }

    .flip-card-inner {
        min-height: 280px;
    }

    .content {
        font-size: 0.9rem;
    }
}

/* Optional animation for initial load */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.flip-card {
    animation: fadeInUp 0.6s ease forwards;
}

.benefits-section {
    padding: 80px 0;
    background-color: #f8f9fa;
}

.benefit-card {
    perspective: 1500px;
    height: auto;
    min-height: 320px;
    cursor: pointer;
    margin-bottom: 20px;
}

.benefit-card-inner {
    min-height: 320px;
    height: 100%;
    position: relative;
    width: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 15px;
}

.benefit-card:hover .benefit-card-inner {
    transform: rotateY(180deg);
}

.benefit-card-front,
.benefit-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    overflow: hidden;
}

.benefit-card-front {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.benefit-card-back {
    background: linear-gradient(135deg, #ff3366, #ff6b6b);
    color: white;
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.benefit-card .icon-box {
    width: 85px;
    height: 85px;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 50%;
    background: rgba(255, 51, 102, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.benefit-card .icon-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.benefit-card:hover .icon-box {
    transform: scale(1.1);
}

.benefit-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.benefit-item {
    text-align: left;
    margin-bottom: 0.75rem;
    font-size: 0.95rem;
    line-height: 1.6;
}

.benefit-item:last-child {
    margin-bottom: 0;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .benefit-card {
        min-height: 350px;
    }

    .benefit-card-inner {
        min-height: 350px;
    }
}

@media (max-width: 991px) {
    .benefit-card {
        min-height: 300px;
    }

    .benefit-card-inner {
        min-height: 300px;
    }
}

@media (max-width: 767px) {
    .benefit-card {
        min-height: 280px;
    }

    .benefit-card-inner {
        min-height: 280px;
    }

    .benefit-item {
        font-size: 0.9rem;
    }
}

/* Animation keyframes (if not already defined) */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Decorative circles */
.decorative-circle {
    position: absolute;
    border-radius: 50%;
    opacity: 0.1;
    z-index: 0;
}

.circle-1 {
    width: 300px;
    height: 300px;
    background: linear-gradient(45deg, #ff3366, #ff6b6b);
    top: -100px;
    right: -100px;
}

.circle-2 {
    width: 200px;
    height: 200px;
    background: linear-gradient(45deg, #6c5ce7, #a8e6cf);
    bottom: -50px;
    left: -50px;
}

/* Device mockup styles */
.device-mockup {
    position: relative;
    width: 280px;
    height: 560px;
    margin: 0 auto;
    perspective: 1000px;
}

.app-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transform: rotateY(-10deg);
    transition: transform 0.5s ease;
}

.device-frame {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 40px;
    pointer-events: none;
}

.device-mockup:hover .app-preview {
    transform: rotateY(0deg);
}

/* Responsive adjustments */
@media (min-width: 992px) {
    .download-section {
        margin: 40px 0;
    }

    .download-title {
        font-size: 2.5rem;
    }

    .download-description {
        font-size: 1.2rem;
    }

    .store-buttons {
        margin: 2rem 0;
    }

    .store-button img {
        height: 60px;
    }
}

@media (max-width: 991px) {
    .download-section {
        padding: 30px;
    }

    .decorative-circle {
        display: none;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1300px;
    }

    .download-section {
        padding: 80px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1440px;
    }
}
</style>