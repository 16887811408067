<template>
    <div>
        <section class="bg-gradient-very-light-gray position-relative py-5">
            <div class="container">
                <!-- Tab Navigation -->
                <div class="row mb-5">
                    <div class="col-12">
                        <ul class="nav nav-tabs justify-content-center border-0 text-center">
                            <li class="nav-item">
                                <a class="nav-link active custom-tab" data-bs-toggle="tab" href="#tab_sec1">
                                    {{ fatchDataOfInternTitle }}
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link custom-tab" data-bs-toggle="tab" href="#tab_sec2">
                                    {{ titleSectionPre }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Tab Content -->
                <div class="tab-content">
                    <!-- First Tab - Internship -->
                    <div class="tab-pane fade show active" id="tab_sec1">
                        <!-- Application Form Row -->
                        <div class="row justify-content-center mb-5">
                            <div class="col-lg-8">
                                <div class="form-card bg-white p-5 rounded shadow-sm">
                                    <h3 class="text-center mb-4">Apply for Internship Program</h3>
                                    <form @submit.prevent="submitForm" id="contactform">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group mb-4">
                                                    <span class="form-icon"><i class="bi bi-person"></i></span>
                                                    <input type="text" 
                                                           class="form-control" 
                                                           placeholder="Your name*" 
                                                           v-model="formData.name" 
                                                           required>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group mb-4">
                                                    <span class="form-icon"><i class="bi bi-envelope"></i></span>
                                                    <input type="email" 
                                                           class="form-control" 
                                                           placeholder="Your email address*" 
                                                           v-model="formData.email" 
                                                           required>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="position-relative form-group mb-4">
                                            <span class="form-icon"><i class="bi bi-briefcase"></i></span>
                                            <input type="text" 
                                                   class="form-control" 
                                                   placeholder="Position applying for*" 
                                                   v-model="formData.subject" 
                                                   required>
                                        </div>

                                        <div class="position-relative form-group mb-4">
                                            <span class="form-icon"><i class="bi bi-upload"></i></span>
                                            <input type="file" 
                                                   class="form-control" 
                                                   @change="handleFileUpload($event)"
                                                   accept=".pdf,.doc,.docx">
                                            <small class="text-muted">Upload your resume (PDF, DOC, DOCX)</small>
                                        </div>

                                        <div class="position-relative form-group mb-4">
                                            <span class="form-icon"><i class="bi bi-chat-square-text"></i></span>
                                            <textarea class="form-control" 
                                                      rows="4" 
                                                      placeholder="Tell us about yourself and why you're interested in this position"
                                                      v-model="formData.message"
                                                      required></textarea>
                                        </div>

                                        <button type="submit" class="btn btn-submit">
                                            <i class="bi bi-send me-2"></i>Submit Application
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Description Row -->
                        <div class="row mt-5">
                            <div class="col-lg-6">
                                <div class="content-card bg-white p-4 rounded shadow-sm">
                                    <h4 class="mb-3">Program Overview</h4>
                                    <p class="lead">{{ fatchDataOfInternContent }}</p>
                                    <img :src="fatchDataOfInternImage" alt="" class="img-fluid mb-4 rounded">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-card bg-white p-4 rounded shadow-sm">
                                    <h4 class="mb-3">Benefits & Perks</h4>
                                    <ul class="benefits-list">
                                        <li v-for="(item, index) in secondSectionContentGet.split('•').slice(1)" 
                                            :key="index"
                                            class="benefit-item">
                                            <i class="bi bi-check-circle-fill text-success me-2"></i>
                                            {{ item.trim() }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="tab_sec2">
                        <div class="row justify-content-center mb-5">
                            <div class="col-lg-8">
                                <div class="form-card bg-white p-5 rounded shadow-sm">
                                    <h3 class="text-center mb-4">Apply for Pre-placement Program</h3>
                                    <form @submit.prevent="submitForm" id="contactform">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="position-relative form-group mb-4">
                                                    <span class="form-icon"><i class="bi bi-person"></i></span>
                                                    <input type="text" 
                                                           class="form-control" 
                                                           placeholder="Your name*" 
                                                           v-model="formData.name" 
                                                           required>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="position-relative form-group mb-4">
                                                    <span class="form-icon"><i class="bi bi-envelope"></i></span>
                                                    <input type="email" 
                                                           class="form-control" 
                                                           placeholder="Your email address*" 
                                                           v-model="formData.email" 
                                                           required>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="position-relative form-group mb-4">
                                            <span class="form-icon"><i class="bi bi-briefcase"></i></span>
                                            <input type="text" 
                                                   class="form-control" 
                                                   placeholder="Position applying for*" 
                                                   v-model="formData.subject" 
                                                   required>
                                        </div>

                                        <div class="position-relative form-group mb-4">
                                            <span class="form-icon"><i class="bi bi-upload"></i></span>
                                            <input type="file" 
                                                   class="form-control" 
                                                   @change="handleFileUpload($event)"
                                                   accept=".pdf,.doc,.docx">
                                            <small class="text-muted">Upload your resume (PDF, DOC, DOCX)</small>
                                        </div>

                                        <div class="position-relative form-group mb-4">
                                            <span class="form-icon"><i class="bi bi-chat-square-text"></i></span>
                                            <textarea class="form-control" 
                                                      rows="4" 
                                                      placeholder="Tell us about yourself and why you're interested in this position"
                                                      v-model="formData.message"
                                                      required></textarea>
                                        </div>

                                        <button type="submit" class="btn btn-submit">
                                            <i class="bi bi-send me-2"></i>Submit Application
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Description Row -->
                        <div class="row mt-5">
                            <div class="col-lg-6">
                                <div class="content-card bg-white p-4 rounded shadow-sm">
                                    <h4 class="mb-3">Program Overview</h4>
                                    <p class="lead">{{ secondSectionContentPre }}</p>
                                    <img :src="secondSectionImagePre" alt="" class="img-fluid mb-4 rounded">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-card bg-white p-4 rounded shadow-sm">
                                    <h4 class="mb-3">Open Positions</h4>
                                    <ul class="benefits-list">
                                        <li v-for="(item, index) in secondSectionContentGet.split('•').slice(1)" 
                                            :key="index"
                                            class="benefit-item">
                                            <i class="bi bi-check-circle-fill text-success me-2"></i>
                                            {{ item.trim() }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Swal from 'sweetalert2';

const formData = ref({
    name: '',
    email: '',
    subject: '',
    message: ''
});
// const sections = ref([]);


const titleSectionExp = ref('Loading...');
const secondSectionContentExp = ref('Loading...');
const secondSectionImageExp = ref('');

const titleSectionGet = ref('Loading...');
const secondSectionContentGet = ref('Loading...');
const secondSectionImageGet = ref('');

const fetchDataFormTitile = ref('Loading...');
const fetchDataFormContent = ref('Loading...');
const fetchDataFormImage = ref('');

const fatchDataOfInternTitle = ref('Loading...');
const fatchDataOfInternContent = ref('Loading...');
const fatchDataOfInternImage = ref('');

const titleSectionPre = ref('Loading...');
const secondSectionContentPre = ref('Loading...');
const secondSectionImagePre = ref('');

// Add file handling
const selectedFile = ref(null);

const handleFileUpload = (event) => {
    selectedFile.value = event.target.files[0];
};

const fetchDataExp = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f492dde93dd002a393c41';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;
        titleSectionExp.value = section.title;

        if (section && section.images && section.images.length > 0) {
            secondSectionImageExp.value = section.images[0].url;
        }
        secondSectionContentExp.value = section.content;
    } catch (error) {

        secondSectionImageExp.value = 'default_image_url'; // replace with your default image url
        secondSectionContentExp.value = 'Error loading content';
    }
};


const fetchDataGetStarted = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4939de93dd002a393c47';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        titleSectionGet.value = section.title;


        if (section && section.images && section.images.length > 0) {
            secondSectionImageGet.value = section.images[0].url;
        }
        secondSectionContentGet.value = section.content;
    } catch (error) {

        secondSectionImageGet.value = 'default_image_url'; // replace with your default image url
        secondSectionContentGet.value = 'Error loading content';
    }
};


const fetchDataFormHeader = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4961de93dd002a393c4f';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        fetchDataFormTitile.value = section.title;


        if (section && section.images && section.images.length > 0) {
            fetchDataFormImage.value = section.images[0].url;
        }
        fetchDataFormContent.value = section.content;
    } catch (error) {

        fetchDataFormImage.value = 'default_image_url';
        fetchDataFormContent.value = 'Error loading content';
    }
};


const fatchDataOfIntern = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4973de93dd002a393c59';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        fatchDataOfInternTitle.value = section.title;


        if (section && section.images && section.images.length > 0) {
            fatchDataOfInternImage.value = section.images[0].url;
            console.log(section.images[0].url);
        }
        fatchDataOfInternContent.value = section.content;
    } catch (error) {

        fatchDataOfInternImage.value = 'default_image_url'; // replace with your default image url
        fatchDataOfInternContent.value = 'Error loading content';
    }
};



const fatchDataOfpre = async () => {
    const page = 'JoinUsPage';

    const sectionID = '665f4980de93dd002a393c65';

    try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/pages/${page}/sections/${sectionID}`);
        const section = response.data;

        titleSectionPre.value = section.title;


        if (section && section.images && section.images.length > 0) {
            secondSectionImagePre.value = section.images[0].url;
        }
        secondSectionContentPre.value = section.content;
    } catch (error) {

        secondSectionImagePre.value = 'default_image_url'; // replace with your default image url
        secondSectionContentPre.value = 'Error loading content';
    }
};

const submitForm = async () => {
    console.log('Form submission started');
    try {
        // Validate form data
        if (!formData.value.name || !formData.value.email || !formData.value.subject || !formData.value.message) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing Information',
                text: 'Please fill in all required fields',
                confirmButtonColor: '#3085d6'
            });
            return;
        }

        // Show loading state
        Swal.fire({
            title: 'Submitting...',
            text: 'Please wait while we process your application',
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.value.name);
        formDataToSend.append('email', formData.value.email);
        formDataToSend.append('subject', formData.value.subject);
        formDataToSend.append('message', formData.value.message);
        
        if (selectedFile.value) {
            formDataToSend.append('resume', selectedFile.value);
        }

        const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/joinus`,
            formDataToSend,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        if (response.data.success) {
            // Show success message
            await Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Your application has been submitted successfully',
                confirmButtonColor: '#3085d6'
            });
            
            // Reset form
            formData.value = {
                name: '',
                email: '',
                subject: '',
                message: ''
            };
            selectedFile.value = null;
        }
    } catch (error) {
        console.error('Submission error:', error);
        
        // Show error message
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response?.data?.message || 'Error submitting form. Please try again.',
            confirmButtonColor: '#3085d6'
        });
    }
};

onMounted(() => {

    fetchDataExp();
    fetchDataGetStarted();
    fetchDataFormHeader();
    fatchDataOfIntern();
    fatchDataOfpre();
});
</script>

<style scoped>
.bg-gradient-very-light-gray {
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.custom-tab {
    color: #333;
    padding: 15px 30px;
    border-radius: 25px;
    transition: all 0.3s ease;
    margin: 0 10px;
}

.custom-tab:hover, .custom-tab.active {
    background-color: #dc3545;
    color: white;
    border: none;
    transform: translateY(-2px);
}

.content-card, .form-card {
    transition: all 0.3s ease;
    border: 1px solid rgba(0,0,0,0.1);
}

.content-card:hover, .form-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;
}

.form-control {
    padding: 12px 45px;
    border-radius: 8px;
    border: 1px solid #dee2e6;
    transition: all 0.3s ease;
    height: auto;
}

.form-control:focus {
    border-color: #0d6efd;
    box-shadow: 0 0 0 0.2rem rgba(13,110,253,.25);
}

.form-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.form-group:has(textarea) .form-icon {
    top: 25px;
    transform: none;
}

input[type="file"].form-control {
    padding: 8px 45px;
}

.form-group:has(input[type="file"]) .form-icon {
    top: 8px;
    transform: none;
}

.form-icon i {
    font-size: 1.1rem;
}

.text-muted {
    display: block;
    margin-top: 0.5rem;
    margin-left: 45px;
}

.btn-submit {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background-color: #dc3545;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-weight: 600;
}

.btn-submit:hover {
    background-color: #c82333;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(220,53,69,0.3);
}

.benefits-list {
    list-style: none;
    padding-left: 0;
}

.benefit-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.content-section {
    color: #495057;
}

.lead {
    font-size: 1.1rem;
    line-height: 1.6;
}

textarea.form-control {
    padding-left: 45px;
}

@media (max-width: 992px) {
    .col-lg-5, .col-lg-6 {
        margin-bottom: 2rem;
    }
}
</style>
